<template>
  <div>
    <div class="control" style="display:flex;align-items:center;margin-bottom:15px;">
    <label class="label" style="display:block;margin-bottom:0;margin-right:15px;">{{ $t('constructor["Link"]') }}</label>
      <v-text-field
        outlined
        dense
        hide-details
        placeholder="Write link with https://"
        v-model="content.content"
      ></v-text-field>
    </div>
    <div class="control" style="font-weight:bold;margin:15px 0;">OR</div>
    <div class="control" style="margin-bottom:15px;">
      <label class="label">You can add file here</label>
      <vue-dropzone
        style="height:150px;"
        id="dropzoneFile"
        ref="dropzoneFile"
        :options="dropzoneOptionsFile"
        @vdropzone-sending="customEventFile"
        @vdropzone-max-files-exceeded="maxFileSexceeded"
        @vdropzone-success="handleSuccessFile"
      >
      </vue-dropzone>
    </div>
  </div>
</template>

<script>
import vueDropzone from "vue2-dropzone";
import { mapState } from 'vuex';
export default {
  components: {
    vueDropzone,
  },
  name: "typeLink",
  props: ["content"],
  data() {
    return {
      dataCount:0,
      dropzoneOptionsFile: {
        url: () => {
          return process.env.VUE_APP_API_URL + "upload-file";
        },
        thumbnailMethod: "contain",
        maxFiles: 1,
        addRemoveLinks: true,
      },
    };
  },
  mounted(){
    this.dataCount += 1;
    this.mountDropzoneFile(this.content.content);
  },
  methods: {
    getFileExtension(filename) {
      if(filename){
      const extension =
        filename.substring(filename.lastIndexOf(".") + 1, filename.length) ||
        filename;
      return extension;
      }else{
        return ""
      }
    },
    maxFileSexceeded(file) {
      this.$refs.dropzoneFile.removeAllFiles();
      this.$refs.dropzoneFile.addFile(file);
    },
    customEventFile(_file, xhr, formData) {
      xhr.setRequestHeader(
        "Authorization",
        `Bearer ${this.$store.state.token}`
      );
      formData.append("hotel_id", this.$route.params.id);
      formData.append("file", _file);
    },
    mountDropzoneFile(img) {
      if (this.getFileExtension(img)&&img.includes('guesteco.com')) {
        const file = {
          name: "Icon",
          type: "image/jpeg",
          dataURL: process.env.VUE_APP_LINK+"/file.png",
        };
        this.$refs.dropzoneFile.manuallyAddFile(
          file,
          process.env.VUE_APP_LINK+"/file.png"
        );
        this.$refs.dropzoneFile.dropzone.emit(
          "thumbnail",
          file,
          file.dataURL
        );
        this.$refs.dropzoneFile.dropzone.emit("complete", file);
      }
    },
    setMenu() {
      this.$store.commit("landing/setMenu", this.menu);
    },
    handleSuccessFile(file) {
      this.content.content = file.xhr.response;
      document.getElementsByClassName("dz-image")[0].style.background =
        "transparent";
      document
        .getElementsByClassName("dz-image")[0]
        .getElementsByTagName("img")[0].src = process.env.VUE_APP_LINK+"/file.png";
      document.getElementsByClassName("dz-details")[0].style.background =
        "transparent";
    },
  },
  computed:{
    ...mapState('landing',['menu'])
  }
};
</script>

<style lang="scss">
.vue-dropzone > .dz-preview .dz-image img{
  max-height:90px;
}
</style>
